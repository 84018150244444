import React from "react";
import Navbar from "../About-us/Navbar/navbar";
import Floating from "../Floating icon/floating";
// import Footer from '../pages/vector/commonfooter/footer'
import Footer from "../../sections/Footer/Footer";
import ContactUsForm from "../SwitchToMac/ContactUsForm";

import PDFViewer from "../../sections/PDFViewer/PDFViewer";
import Breadcrum from "../../sections/Breadcrum/Breadcrum";
import { Helmet } from "react-helmet-async";

import CanalysReportPDF from "../../assets/data/canalys_report.pdf";

function CanalysReport() {
  return (
    <>
      <Helmet>
        <title>Datalogics India | Canalys Report</title>
        <link
          rel="canonical"
          href={"https://www.datalogicsindia.com/canalys-report"}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={"Datalogics India | Canalys Report"}
        />
        <meta
          property="og:url"
          content={"https://www.datalogicsindia.com/canalys-report"}
        />
        <meta
          property="og:site_name"
          content="Datalogics India | Canalys Report"
        />
        <meta
          name="twitter:title"
          content={`Datalogics India | Canalys Report`}
        />
        <meta name="twitter:site" content="@datalogicsindia" />
        <meta name="twitter:creator" content="@Datalogics_IN" />
      </Helmet>
      <Navbar />
      <Floating />
      <div className="container mt-5">
        <div className="row pt-3 pt-lg-5">
          <Breadcrum textColor={"#000000"} />
        </div>
      </div>
      <PDFViewer
        doc={CanalysReportPDF}
        title="Canalys Report"
        filename="Canalys Report"
      />
      <ContactUsForm
        ctaMain="Talk to Our Apple Specialist"
        ctaPara="Discover how Macs with Apple's M Series chips can transform your AI workflows and boost productivity."
        subject="Canalys Report page Form Response"
      />
      <Footer />
    </>
  );
}

export default CanalysReport;
