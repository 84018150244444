import "./SkochReport.css";
import React from "react";
import Navbar from "../About-us/Navbar/navbar";
import Floating from "../Floating icon/floating";
// import Footer from '../pages/vector/commonfooter/footer';
import Footer from "../../sections/Footer/Footer";
import ContactUsForm from "../SwitchToMac/ContactUsForm";
import PDFViewer from "../../sections/PDFViewer/PDFViewer";
import Breadcrum from "../../sections/Breadcrum/Breadcrum";
import { Helmet } from "react-helmet-async";
import SkochReportPDF from "../../assets/data/Skoch_report.pdf";

function SkochReport() {
  return (
    <>
      <Helmet>
        <title>Datalogics India | Skoch Report</title>
        <link
          rel="canonical"
          href={"https://www.datalogicsindia.com/skoch-report"}
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={"Datalogics India | Skoch Report"} />
        <meta
          property="og:url"
          content={"https://www.datalogicsindia.com/skoch-report"}
        />
        <meta
          property="og:site_name"
          content="Datalogics India | Skoch Report"
        />
        <meta
          name="twitter:title"
          content={`Datalogics India | Skoch Report`}
        />
        <meta name="twitter:site" content="@datalogicsindia" />
        <meta name="twitter:creator" content="@Datalogics_IN" />
      </Helmet>
      <Navbar />
      <Floating />
      <div className="container mt-5">
        <div className="row pt-3 pt-lg-5">
          <Breadcrum textColor={"#000000"} />
        </div>
      </div>
      <PDFViewer
        doc={SkochReportPDF}
        title="Skoch Report"
        filename="Skoch Report"
      />
      <ContactUsForm
        ctaMain="Talk to Our Apple Specialist"
        ctaPara="Discover how a Mac fleet can be more cost-effective than Windows PCs in the long run."
        subject="Skoch Report page Form Response"
      />
      <Footer />
    </>
  );
}

export default SkochReport;
