import React, { useState } from "react";
import FloatingPalm from "../../assets/logos/floating-palm.webp";
import { HashLink } from "react-router-hash-link";
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./floating.css";


function Floating() {

    const[showNav, setShowNav] = useState(false);
    let navigate = useNavigate(); 
    const routeChange = () =>{ 
      let path = `/`; 
      navigate(path);
      window.scrollTo(0,0)
    }
    
    const showMenu = () => {
      setShowNav(!showNav)
      console.log(showNav)
    }

    if(showNav){
      window.addEventListener("scroll", ()=> {
        setShowNav(false)
      })
    }

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

  return (
    <>
      <div className="floating-container">
      <div className="floating-nav-menu" style={{right: showNav ? "15px" : "-175px"}}>
          <HashLink to="/people#people" onClick={() => setShowNav(!showNav)} className="text-decoration-none"><p className={splitLocation[1] === "people" ? "active-menu" : ""}>People</p></HashLink>
          <HashLink to="/service#Home" onClick={() => setShowNav(!showNav)} className="text-decoration-none"><p className={splitLocation[1] === "service" ? "active-menu" : ""}>Service</p></HashLink>
          <HashLink to="/enterprise#Home" onClick={() => setShowNav(!showNav)} className="text-decoration-none"><p className={splitLocation[1] === "enterprise" ? "active-menu" : ""}>Enterprise</p></HashLink>
          <HashLink to="/education#Home" onClick={() => setShowNav(!showNav)} className="text-decoration-none"><p className={splitLocation[1] === "education" ? "active-menu" : ""}>Education</p></HashLink>
          <HashLink to="/vectorworks#Home" onClick={() => setShowNav(!showNav)} className="text-decoration-none"><p className={splitLocation[1] === "vectorworks" ? "active-menu" : ""}>Vectorworks</p></HashLink>
          <HashLink to="/service/dcare#Home" onClick={() => setShowNav(!showNav)} className="text-decoration-none"><p className={splitLocation[2] === "dcare" ? "active-menu" : ""}>DCare</p></HashLink>
          <Link to="/" onClick={routeChange} className="text-decoration-none"><p className={splitLocation[1] === "" ? "active-menu m-0" : "m-0"}>About Us</p></Link>
      </div>
        <div className="floating-button" onClick={showMenu}>
          <img className="Floating-img" src={FloatingPalm} alt={""} width={30}/>
        </div>
      </div>
    </>
  );
}

export default Floating;
