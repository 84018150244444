import React, { useState } from "react";
import axios from "axios";
import "./ContactUsForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ContactUsForm({ ctaMain, ctaPara, subject, margin = "mt-5" }) {
  const [name, setName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [userOrganization, setUserOrganization] = useState("");
  const [userMessage, setUserMessage] = useState("");

  const updateNumber = (value) => {
    const re = /^[0-9\b]{0,10}$/;
    // if value is not blank, then test the regex

    if (value === "" || re.test(value)) {
      setUserNumber(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setName("");
    setUserEmail("");
    setUserNumber("");
    setUserOrganization("");
    setUserMessage("");

    console.log("Email Send Successfully", name);
    const headers = {
      "Content-Type": "application/json",
      "api-key":
        "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",
    };

    const emailData = {
      name: subject ? subject : "Switch To Mac Form response",
      sender: {
        email: userEmail,
        name: name,
      },
      to: [
        {
          email: "marcom@datalogicsindia.com",
        },
      ],
      subject: subject ? subject : "Form response",
      htmlContent: `<html>
                              <body> 
                                  <p>Name : ${name} </p> 
                                  <br />
                                  <p>Email : ${userEmail} </p>
                                  <br /><p>Number : ${userNumber} </p>  
                                  <br />
                                  <p>Organization : ${userOrganization} </p> 
                                  <br /> 
                                  <p>Message : ${userMessage} </p> 
                              </body>
                          </html>`,
      headers: {
        "X-Mailin-custom":
          "custom_header_1:custom_value_1|custom_header_2:custom_value_2|custom_header_3:custom_value_3",
        charset: "iso-8859-1",
      },
    };

    axios
      .post("https://api.brevo.com/v3/smtp/email", JSON.stringify(emailData), {
        headers: headers,
      })
      .then((response) => {
        console.log("send", response);
        toast.success("Request Submitted!");
      })
      .catch((err) => console.log(err));
  };

  return (
    <div
      className={`container-fuild SwitcherFormContainer-Contact-Us ${margin}`}
      id="switchToMacForm"
    >
      <div
        className="container generalFormContainer"
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-once="true"
      >
        <ToastContainer position="bottom-right" />
        <div className="form-container" style={{ backgroundColor: "#f1f1f1" }}>
          <div className="Text-CTA">
            <h1 style={{ color: "#d80128" }}>{ctaMain}</h1>
            <p>{ctaPara}</p>
          </div>

          <div className="form-general">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="Name"
                id="Name"
                placeholder="Name"
                onChange={(event) => setName(event.target.value)}
                value={name}
                required
              />
              <input
                type="email"
                name="Email"
                id="Email"
                placeholder="Email"
                onChange={(event) => setUserEmail(event.target.value)}
                value={userEmail}
                required
              />
              <input
                type="tel"
                name="Mobile"
                id="Mobile"
                placeholder="Mobile"
                onChange={(event) => updateNumber(event.target.value)}
                value={userNumber}
                required
              />
              <input
                type="text"
                name="Organization"
                id="Organization"
                placeholder="Organization"
                onChange={(event) => setUserOrganization(event.target.value)}
                value={userOrganization}
                required
              />
              <textarea
                name="Message"
                id="Message"
                cols="30"
                rows="5"
                placeholder="Whats on your Mind?"
                onChange={(event) => setUserMessage(event.target.value)}
                value={userMessage}
                required
              ></textarea>
              <input type="submit" value="Submit" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsForm;
